<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="aligncenter size-full wp-image-23025" src="@/assets/news/7/1.jpg" alt="图片" width="600" height="400"></p>
        <p><b>项目简介：</b></p>
        <p>2020 年 7 月 9 日，安省移民局官网公布安省雇主试点项目开始接受申请，为期两年，额外提供 150 个名额指定给安省的 3 个试点区域（城市）：</p>
        <p>·Chatham-Kent 查塔姆 – 肯特<br>
          ·Cities of Belleville and Quinte West（贝尔维尔 / 西昆特地区）<br>
          ·City of Cornwall 康沃尔</p>
        <p>&nbsp;</p>
        <figure data-size="normal"></figure>
        <p><b>安省雇主担保移民分为三种方式：</b></p><b>
      </b><p><b></b></p>
        <p><b>1.雇主类移民（需雇主offer）</b></p>
        <p>技术移民、留学生、需求技能</p>
        <p>安省的省提名项目，大部分都需要雇主Offer，对于海外申请人来说，安省雇主担保的优点是在本国直接拿PR；</p>
        <p><b>2.无雇主类移民（无需雇主offer）</b></p>
        <p>硕博类（硕士毕业生、博士毕业生）、 EE类（HCP优才、法语技能、技术工人）</p>
        <p>这类移民项目要求申请人在安省硕、博士毕业两年内或满足加拿大EE类别的优才项目且EE分数在400分以上才可以申请；</p>
        <p><b>3.商业移民</b></p>
        <p>投资类（已关闭）、创建/购买分公司股份、创业类</p>
        <p>安省商业移民类别中投资移民暂时关闭，但是创业移民的门槛比较低，有兴趣的可以单独了解。</p>
        <figure data-size="normal"></figure>
        <p><b>项目优势：</b></p>
        <p>1、一人申请，全家移民</p>
        <p>2、没有资产要求，无需解释资金来源</p>
        <p>3、不同于安省常规雇主类别需要抢名额注册，试点项目可以直接递交申请给安省移民局，收到通知后进行下一步的申请</p>
        <p>4、申请暂无雅思要求</p>
        <figure data-size="normal"></figure>
        <p><b>申请条件：</b></p>
        <p>1、针对人群 – 有资深经验的国内人士（建议 50 岁以下）</p>
        <p>2、学历要求 – 国内大专以上学历（建议）</p>
        <p>3、工作经验 – 申请前 5 年内有 2 年的有效工作经验（必须）</p>
        <p>4、需要参加 EOI 抽选</p>
        <p><strong>其他要求：</strong></p>
        <p>1、申请人在三个试点区域内获得一份符合职业类别 NOC0/A/B 的全职工作</p>
        <p>2、工资符合同省份范围内该职位的普遍公司标准</p>
        <p>3、申请前 2 年有效工作经验与目标职业的 NOC 类别相同</p>
        <p>4、雇主的要求：成立时间至少 3 年，年营业额至少 50 万加币，全职员工至少 3 人</p>
        <p>&nbsp;</p>
        <p><b>雇主要求：</b></p>
        <p>1. 目前在正常运营，在安省有固定办公场所并符合安省劳动法相关要求的企业或公司；</p>
        <p>2. 如果公司地点是在大多伦多地区，要求在上一财政年度的营业额度不少于100万加元，并已雇有5个全职雇员( 为加国公民或永久居民)；</p>
        <p>3. 如果公司地点在大多伦多地区以外，要求在上一财政年度的营业额度不少于50万加元，并已雇有3个全职雇员( 为加国公民或永久居民)。</p>
        <figure data-size="normal"></figure>
        <p><b>申请流程：</b></p>
        <ol>
          <li>免费评估、制定方案（约3天）；</li>
          <li>签署合同、匹配雇主（约2周）；</li>
          <li>发布广告、准备材料、雇佣合同（约4-6周）；</li>
          <li>申请提名、开始建档获档案号（约3-4个月）；</li>
          <li>申请移民、办工签（约1个月）；</li>
          <li>下工作签、可入境（可不入境）；</li>
          <li>体检报告、收大信封（约12个月）；</li>
          <li>完成登录、获得PR（约3个月）。</li>
        </ol>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "7",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:7,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>